<template>
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="416"
      width="512"
      height="96"
      rx="32"
      fill="white"
    />
    <rect
      y="208"
      width="352"
      height="96"
      rx="32"
      fill="white"
    />
    <rect
      x="416"
      y="136"
      width="96"
      height="240"
      rx="32"
      fill="#528BFF"
    >
      <animate
        :values="`#528BFF;#528BFF;rgba(0,0,0,0);rgba(0,0,0,0);#528BFF;#528BFF;`"
        attributeType="XML"
        attributeName="fill"
        dur="1.5s"
        repeatCount="4"
      />
    </rect>
    <rect
      width="512"
      height="96"
      rx="32"
      fill="white"
    />
  </svg>
</template>
